import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Layout from '../../../../../components/Layout';
import Seo from '../../../../../components/SEO';
import QuestionSection from '../../../../../components/QuestionSection';
import {ConnectorIntro, SeeAllArrow, ConnectorOverview} from '../../../../../components/systems';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

const Custom = () => {
  const connectorName = 'custom';
  const connectorImages = useStaticQuery(graphql`
    query {
      allFile(filter: {absolutePath: {regex: "/systems/custom/"}, extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
        edges {
          node {
            name
            relativePath
            base
            childImageSharp {
              gatsbyImageData(width: 1140, layout: CONSTRAINED, placeholder: BLURRED, quality: 100, formats: AUTO)
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const edgesImages = connectorImages.allFile.edges;
  const images = {
    overview: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-overview`),
    featureOne: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-01`)[0]?.node,
    featureTwo: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-02`)[0]?.node,
    logo: edgesImages.filter((edge) => edge.node.name === `${connectorName}`),
  };

  const connector = {
    intro: {
      logo: images.logo,
      name: 'Custom',
      description: `<u>Trustle</u> offers two methods for generic integrations to target systems: File-Based and Manual. File-based integrations use comma separated value (CSV) files to interact with a system, based on a columnar schema that represents Trustle functions. The Manual connector uses Trustle’s workflow engine to assign and manage tasks that system administrators then perform on the target system.`,
    },
    overview: {
      title: 'Custom Connectors w/ Trustle',
      overview: 'File-Based Integration',
      description: `Trustle uses a flexible CSV format that can work with a wide variety of target systems without requiring a lot of file manipulation. Using this format, you can upload user accounts, make permissions changes, manage group memberships, and more. The format allows for variations in resource types that target systems use, such as group, role, repository, team, and even arbitrary permission names.`,
    },
  };

  const {intro, overview} = connector;

  return (
    <Layout
      seo={
        <Seo
          title="Custom System | Trustle"
          description="Trustle offers two methods for generic integrations to target systems: File-Based and Manual. File-based integrations use comma separated value (CSV) files to interact with a system, based on a columnar schema that represents Trustle functions. The Manual connector uses Trustle’s workflow engine to assign and manage tasks that system administrators then perform on the target system."
        />
      }
    >
      <section className="pt-16 overflow-hidden transform bg-gradient-to-b from-gray-200 via-white to-gray-200">
        <div className="flex items-center px-4 pb-12 md:py-8 lg:mx-auto max-w-[1360px] font-bold text-sm md:text-xl text-blue">
          <SeeAllArrow />
        </div>

        <div className="max-w-[1360px] mx-auto text-blue sm:pt-8 text-center">
          {connector && (
            <>
              <ConnectorIntro name={intro?.name} description={intro?.description} logo={intro?.logo} isPageHeader />

              <ConnectorOverview
                title={overview?.title}
                overview={overview?.overview}
                description={overview?.description}
                image={images?.overview}
              />

              {/* to do: create this as component */}
              <div className="pb-8 mx-8 mb-8">
                {images?.featureOne && (
                  <GatsbyImage
                    fluid={images.featureOne.fluid}
                    alt="dashboard"
                    image={getImage(images.featureOne)}
                    className="drop-shadow-xl md:drop-shadow-2xl"
                  />
                )}
              </div>

              {/* To do: update to component */}
              <div className="mx-8">
                <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl">Manual Connector</h2>
                <p className={`md:text-lg text-left max-w-8xl m-auto mb-8 md:mb-16 text-blue`}>
                  Manual connections enable you to take advantage of Trustle’s workflow system to task, notify, and
                  resolve access requests, even when the provisioner is not the system owner or when file-based access
                  isn’t available for the target system. Trustle’s task manager keeps track of all the requests,
                  including history, and enables you to create reports an workflow activities. Provisioners can use
                  email and Slack to receive and handle requests.
                </p>
              </div>

              {/* To do: update to component */}
              <div className="mx-8 mb-8 md:mb-16">
                <div className="flex flex-col justify-between xl:flex-row ">
                  <div className="">
                    <div className="max-w-[580px]">
                      <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl no-wrap">
                        Communicating in Your Everyday Tools
                      </h2>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        You can manage in Trustle but your system administrators can be notified and respond in email
                        and Slack.
                      </p>
                    </div>
                  </div>
                  <div className="pb-8 max-w-[515px]">
                    {images?.featureTwo && (
                      <GatsbyImage
                        fluid={images.featureTwo.fluid}
                        alt="dashboard"
                        image={getImage(images.featureTwo)}
                        className="drop-shadow-xl md:drop-shadow-2xl"
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <QuestionSection />
    </Layout>
  );
};
export default Custom;
